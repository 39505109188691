<template>
  <div v-loading="loading">
    <div class="text-center mb-2">
      <el-radio-group v-model="field.is_global_variable">
        <el-radio-button :label="false">Custom Field</el-radio-button>
        <el-radio-button :label="true">Global Variable</el-radio-button>
      </el-radio-group>
    </div>
    <el-form label-position="right" v-if="!field.is_global_variable">
      <title-and-description :field="field" />
      <field-attributes :field="field" />
      <el-row :gutter="30">
        <el-col :span="12">
          <el-form-item label="Upload Type:">
            <div class="d-flex mt-10">
              <el-radio v-model="field.validations.multiple" :label="false"
                >Single</el-radio
              >
              <el-radio v-model="field.validations.multiple" :label="true"
                >Multiple</el-radio
              >
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row :gutter="30">
        <div v-if="field.validations.multiple" style="margin-bottom: 2px">
          <el-col :span="6">
            <div class="form-group">
              <el-form-item label="Min Images">
                <el-input-number
                  v-model="field.validations.min_image_files"
                ></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="form-group">
              <el-form-item label="Max Images">
                <el-input-number
                  v-model="field.validations.max_image_files"
                ></el-input-number>
              </el-form-item>
            </div>
          </el-col>
          <el-col :span="10">
            <is-field-required :field="field" class="field-required" />
          </el-col>
        </div>
      </el-row>
      <el-row type="flex" :gutter="30" style="margin-top: 10px">
        <el-col
          :span="12"
          v-if="field.filled_by != 'RECEIVER' && !field.validations.multiple"
        >
          <p>Select Image to Upload</p>

          <el-upload
            class="upload-file"
            drag
            name="logo"
            :on-change="uploadImageFile"
            action
            :show-file-list="false"
            :auto-upload="false"
            accept=".png, .jpg, .jpeg, .gif"
          >
            <div v-if="!fileUrl" style="padding: 1em">
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">
                Drop file here or <em>click to upload</em>
              </div>
            </div>
            <!-- <div v-if="fileUrl">
                 <img style="width:100%;height:90%" v-loading="loading" class="avatar" :src="logoUploadUrl+fileUrl"  />
                  </div> -->
          </el-upload>
          <!-- <el-upload
               v-if="field.upload_type === 'UPLOAD'"
                  drag
                  name="upload"
                  :accept="allowed_file_types"
                  :action="UploadUrl"
                  :on-change="uploadFile"
                  :headers="uploadHeaders"
                  :on-success="handleLogoSuccess"
                  :on-error="handleLogoError"
                  :before-upload="beforeLogoUpload"
                  :thumbnail-mode="true"
                  :show-file-list="false"
                >
              
                <div v-if="!fileUrl" style="padding:1em;">
                    <i class="el-icon-upload"></i>
                      <div class="el-upload__text">Drop file here or <em>click to upload</em></div>
                    
                </div>
                <div v-if="fileUrl">
                 <img style="width:100%;height:90%" v-loading="loading" class="avatar" :src="logoUploadUrl+fileUrl"  />
                  </div>
                
                </el-upload> -->
        </el-col>
        <el-col :span="12" v-if="field.filled_by != 'RECEIVER'">
          <p :style="getStyle">
            <template v-if="field.image_url">
              <!-- {{this.field.image_url}} -->
              <img style="width: 100%; height: 90%" :src="field.image_url" />
            </template>
            <template v-else>
              <div v-if="field.folder" @click="openGallery">
                <img
                  :src="field.folder.thumbnail"
                  alt="folder thumbnail"
                  style="width: 100%; height: 90%"
                  :class="{ 'focus-border-input': highlight }"
                />
                <GalleryModal
                  v-if="showGallery"
                  :images="field.folder.images"
                  :isView="isView"
                  @close="showGallery = false"
                />
              </div>
            </template>
            <!-- <template v-else></template> -->
          </p>

          <el-form-item label="Link" v-if="field.upload_type === 'LINK'">
            <el-input placeholder="Image Link" v-model="image_url" />
          </el-form-item>
        </el-col>
        <!-- {{ field }} -->
        <!-- <el-col :span="12" v-if="field.filled_by!='RECEIVER'">
          <el-form-item label="Image Upload Type">
            <el-select
              clearable
              v-model="field.upload_type"
              placeholder="Select Image Upload Type"
            >
              <el-option
                v-for="item in options"
                :key="item"
                :label="item"
                :value="item"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-col> -->
        <!-- <el-col :span="24">
          <field-filled-by :field="field" />
          <el-col :span="24">
        <is-field-required :field="field" class="field-required"/>
        </el-col>
        </el-col> -->
      </el-row>
      <el-row
        type="flex"
        :gutter="30"
        style="margin-top: 10px"
        v-if="field.filled_by != 'RECEIVER'"
      >
      </el-row>
    </el-form>
    <el-form label-position="right" v-if="field.is_global_variable">
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <div class="form-group">
            <el-form-item label="Global Variable">
              <el-select
                v-model="field.global_variable_id"
                @change="setGlobalVariable"
              >
                <el-option
                  v-for="(globalVariable, index) of allGlobalVariables"
                  :key="index"
                  :label="globalVariable.label"
                  filterable
                  :value="globalVariable._id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30">
        <el-col :span="12">
          <placeholder :field="field" />
        </el-col>
        <el-col :span="12">
          <field-filled-by :field="field" />
        </el-col>
      </el-row>
      <el-row type="flex" :gutter="30"> </el-row>
      <is-field-required :field="field" class="field-required" />
    </el-form>
  </div>
</template>

<script>
// import TitleAndDescription from "./TitleAndDescription";
import { mapGetters } from "vuex";
import appConfig from "@/config/app";
import axios from "@/config/axios";
import { postAPICall } from "../../../helpers/httpHelper";
import GalleryModal from "../formComponentsExecute/GalleryModel.vue";
export default {
  name: "templates-formComponents-Image",
  components: {
    TitleAndDescription: () => import("./TitleAndDescription"),
    IsFieldRequired: () => import("./IsFieldRequired"),
    FieldFilledBy: () => import("./FieldFilledBy"),
    Placeholder: () => import("./Placeholder"),
    FieldAttributes: () => import("./FieldAttributes"),
    GalleryModal,
  },
  props: ["field", "isView"],
  data() {
    return {
      validations: this.field.validations || { multiple: false },
      highlight: false,
      file: "",
      image_url: "",
      loading: false,
      fileUploading: false,
      options: ["UPLOAD"],
      // options: ["UPLOAD", "LINK"],
      fileUrl: "",
      fileUploadError: "",
      imageWidth: 100,
      fileTypes: "image/jpeg,image/png",
      allowed_file_types: this.fileTypes,
      imgDataWidth: 0,
      imgDataHeight: 0,
      imgData: null,
      folder: null,
      showGallery: false,
      form: this.form || {},
    };
  },
  computed: {
    ...mapGetters("globalVariables", ["getAllGlobalVariables"]),
    allGlobalVariables() {
      return this.getAllGlobalVariables
        ? this.getAllGlobalVariables.data || []
        : [];
    },
    logoUploadUrl() {
      return process.env.VUE_APP_S3_BUCKET_URL;
    },
    UploadUrl() {
      return appConfig.API_URL + "/uploadToS3";
    },
    uploadHeaders() {
      return {
        Authorization: this.getAuthenticationDetails.access_token,
      };
    },
    getImageData() {
      return this.field.image_url;
    },
    getStyle() {
      return this.hasLabel ? `height:${this.field.height - 30}px` : "";
    },
    getUploadPreviewStyle() {
      return this.fileUrl ? `width:${100 / this.fileUrl}%` : "width:100%";
    },
    ...mapGetters("companyTemplates", ["getImageUploadData"]),
    ...mapGetters("templatesData", ["getDownloadUrl"]),
    ...mapGetters("auth", ["getAuthenticationDetails"]),
    ...mapGetters("s3FileUpload", ["getFileUploadData", "getFileUploadStatus"]),
    getFileName() {
      let fileNames = this.field["upload_file"].name.split(".");

      if (fileNames.length > 1) {
        let name = fileNames[0];
        let ext = fileNames.pop();
        if (name.length > 20) {
          return name.slice(0, 20) + "." + ext;
        } else {
          return name + "." + ext;
        }
      } else {
        return name.slice(0, 20);
      }
    },
  },
  async mounted() {
    if (!this.field.validations) {
      this.field.validations = { multiple: false };
    }
    this.fetchGlobalVaribales();
    this.imgData =
      this.field && this.field.image_url ? this.field.image_url : "";
  },
  methods: {
    async fetchGlobalVaribales() {
      let params = {
        get_all: true,
        input_type: this.field.input_type,
      };

      await this.$store.dispatch(
        "globalVariables/fetchGlobalVariables",
        params
      );
    },
    setGlobalVariable() {
      let globalVariable = this.allGlobalVariables.find(
        (x) => x._id == this.field.global_variable_id
      );
      this.field.label = globalVariable.label;
      this.field.description = globalVariable.description;
      this.field.image_url = globalVariable.image_url;
    },
    async uploadImageFile(file) {
      try {
        this.loading = true;
        if (file.size < 2111775) {
          if (
            ["image/jpeg", "image/png", "image/jpg", "image/gif"].includes(
              file.raw.type
            )
          ) {
            const formData = new FormData();
            formData.append("files", file.raw);

            await this.$store.dispatch("s3FileUpload/uploadFileToS3", formData);
            if (this.getFileUploadStatus && this.getFileUploadData) {
              this.$set(this.field, "image_url", this.getFileUploadData);
              this.loading = false;
              const base64Image = await this.getBase64(file.raw);
              const img = document.createElement("img");
              img.src = base64Image;
              img.onload = () => {
                this.field.width = img.width;
                this.field.height = img.height;
              };
            } else {
              this.$alert("Error while uploading file");
            }
          } else {
            this.$alert("Please upload a valid image file");
          }
        } else {
          this.$alert("File size must not exceed 2MB");
        }
      } catch (error) {
        this.$alert("An error occurred while uploading the file");
      } finally {
        this.loading = false;
      }
    },
    getBase64(file) {
      return new Promise(function (resolve, reject) {
        let reader = new FileReader();
        let imgResult = "";
        reader.readAsDataURL(file);
        reader.onload = function () {
          imgResult = reader.result;
        };
        reader.onerror = function (error) {
          reject(error);
        };
        reader.onloadend = function () {
          resolve(imgResult);
        };
      });
    },
    async uploadFile() {
      this.fileUploadError = "";
      this.loading = false;
    },
    handleLogoSuccess(res, file) {
      console.log(file);

      this.fileUrl = res.Location;
      this.imageWidth = 100 / this.fileUrl;
      (this.field.image_url = this.fileUrl), (this.loading = false);
      this.$notify.success({
        title: "success",
        message: "Updated Successfully",
      });
    },
    async beforeLogoUpload(file) {
      this.loading = true;
      let isValidMime = true;
      isValidMime = file.size / 1024 / 1024 < 2;
      if (!isValidMime) {
        this.$message.error("picture size can not exceed 2MB!");
      }
      this.loading = false;
      return isValidMime;
    },

    handleLogoError(res) {
      res = JSON.parse(res.message);
      if (!res.success) {
        this.fileUploadError = res.message;
      }
      this.loading = false;
    },

    async upload(file) {
      let formData = new FormData();
      // this.currentImage = this.$refs.file.files.item(0);
      formData.append("files", file);
      formData.append("pathRepo", "DOCUMENT");

      let uploadInfo = await axios.post("/uploadToS3", formData);
      console.log(uploadInfo);

      this.$set(this.field, "upload_file", {
        type: "DOCUMENT",
        name: file.name,
        file: file,
        new: true,
        file_name: file.name,
        file_type: file.raw.type,
        file_size_in_kb: file.size / 1024,
      });
    },

    // upload(file) {
    //   this.file = file.raw
    //   this.$set(this.field, 'upload_file', {
    //     type: "IMAGE",
    //     name: file.name,
    //     file: file,
    //     new: true,
    //     file_name: file.name,
    //     file_type: file.raw.type,
    //     file_size_in_kb: file.size / 1024
    //   });
    // },
    // async fileUpload() {
    //   if (this.file) {
    //     let payload = new FormData()
    //     payload.append('upload', this.file)
    //     this.loading = true
    //     await this.$store.dispatch('companyTemplates/uploadImage', payload)
    //     this.field.image_url = this.getImageUploadData.url
    //     this.loading = false
    //   }
    // },
    linkSubmit() {
      this.loading = true;
      this.field.image_url = this.image_url;
      this.loading = false;
    },
    async handleFileUpload(event) {
      const files = Array.from(event.target.files);

      const minFiles = this.field.validations.min_image_files;
      const maxFiles = this.field.validations.max_image_files;
      const currentFileCount = this.field.folder
        ? this.field.folder.images.length
        : 0;
      const newFileCount = files.length;
      const totalFileCount = currentFileCount + newFileCount;

      if (totalFileCount < minFiles || totalFileCount > maxFiles) {
        this.handleExceed(files);
        event.target.value = "";
        return;
      }
      this.handleAutofillFields(files);
    },
    async handleAutofillFields(file) {
      // try {
      this.loading = true;
      this.$emit("loadComponent", {
        value: true,
        loadingText: "Extracting data from the file...",
      });
      var formData = new FormData();
      file.forEach((el) => {
        formData.append("files", el !== undefined ? el : el);
      });
      let response = await postAPICall(
        "PUT",
        `/upload/file-Mul_upload-s3`,
        formData
      );
      let newImage = response.data.map((obj) => obj.Location);
      if (this.field.folder) {
        this.field.folder.images.push(...newImage);
      } else {
        const thumbnail = newImage[0];
        this.field.folder = { images: newImage, thumbnail };
      }
      this.loading = false;
    },
    handleExceed(files) {
      this.$message.error(
        `You have exceeded the file selection limit.The allowed limit is ${
          this.field.validations.min_image_files
        } - ${
          this.field.validations.max_image_files
        } files, but you have selected ${
          files.length
        } files. Please reduce your selection to ${
          files.length - this.field.validations.max_image_files
        } files or fewer to proceed`
      );
    },
    openGallery() {
      this.showGallery = true;
    },
    async default() {
      this.field.validations.multiple = false;
    },
  },
};
</script>

<style scoped>
.custom-file-upload {
  display: inline-block;
  height: 200px;
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  box-sizing: border-box;
  position: relative;
  cursor: pointer;
  text-align: center;
}

.upload-area {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 360px !important;
}

.el-upload__text {
  color: #606266;
  font-size: 14px;
  text-align: center;
  margin-top: 10px;
}

.custom-file-upload input[type="file"] {
  display: none; /* Hide the file input */
}
.el-icon-upload:before {
  font-size: 67px;
  color: #c0c4cc;
  line-height: 50px;
}
.upload-area .el-upload__text em {
  color: #409eff;
  font-style: normal;
}
::v-deep .upload-area:hover {
  border-color: #409eff !important;
}
</style>
